<template>
  <div>
    <TopMenuSubheadline :title="title" />
    <div class="app-content-box bg-gray">
      <div class="wrapper-authors container" v-if="isLoaded">
        <div class="books-list-masonry">
          <div v-for="(res, $index) in list" :key="$index">
            <router-link :to="{name: 'BooksDetails', params: {id: res.id}}">
              <div class="book-card">
                <div class="book-cover-container">
                  <div class="book-cover" v-for="(item, id) in 1" :key="id">
                    <img :src="res.image ? (coverUrl + res.image) : (coverUrl + 'no_cover.png')"
                    />
                  </div>
                  <div class="bottom-plus-sign" @click.prevent="showModal(res.id)"><i class="fas fa-plus-circle"></i></div>
                </div>
                <div class="mb-3">
                  <span class="book-info mt-3">{{ res.tytul }}</span>
                </div>
                <div class="stars-wrapper mt-0 mb-0" v-if="res.rating > 0">
                  <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
                  inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
                  :border-width='borderWidth' :show-rating="false" :read-only="true"
                  :rounded-corners='rounded' :rating="parseFloat(res.rating)" >
                  </star-rating>
                </div>
                <div class="rating-size mt-1 mb-3 text-dark" v-if="res.rating > 0">
                  Ocena: {{parseFloat(res.rating, 10)}}
                </div>
              </div>
            </router-link>
            <ModalSlot title="Dodaj książkę" v-if="isPopupShown && check_id === res.id" @close-modal="closeModal">
              <div class="text-center mb-2 modal-main-title">Dodaj książkę</div>
              <div class="modal-wrapper">
                <div class="growing-slide-cover wrapper-cover w-100">
                  <img :src="res.image ? (coverUrl + res.image) : (coverUrl + 'no_cover.png')" class="slide-cover-image"/>
                  </div>
                  <div class="book-heading-big text-center mt-3">{{res.autor}}</div>
                  <div class="book-heading-small text-center mt-2">{{res.tytul}}</div>
              </div>
              <router-link :to="{name: 'BorrowBook', params: {id: res.id}}"><button class="btn-black-border w-100 mt-3 mb-1">Dodaj na półkę</button></router-link>
              <button class="btn-black-border w-100 mt-3 mb-1" @click="saveToLater(res.id)">Zapisz na później</button>
              <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
            </ModalSlot>
          </div>
        </div>
        <infinite-loading @infinite="handleBooks">
          <div slot="spinner" class="d-flex align-items-center justify-content-center w-100">
            <img src="../assets/loaders/loader_on_overlay.gif" alt="loader" />
          </div>
          <div slot="no-more" class="mb-2">To już wszystko...</div>
         <div slot="no-results" class="mb-2"></div>
        </infinite-loading>
      </div>
      <div v-else>
        <Loader />
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import Loader from '../components/Loader'
import ModalSlot from '../components/ModalSlot'
import StarRating from 'vue-star-rating'
export default {
  name: 'Authors',
  components: {
    TopMenuSubheadline,
    Loader,
    ModalSlot,
    StarRating
  },
  props: {
    id: [String, Number]
  },
  data () {
    return {
      author: [],
      authorBooks: [],
      isLoaded: false,
      title: '',
      page: 1,
      list: [],
      isPopupShown: false,
      check_id: 0,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 14
    }
  },
  async created () {
    await this.handleBooks()
  },
  methods: {
    saveToLater (id) {
      const getData = 'status=later&own=1&borrowed=0'
      this.$https('book/' + id + '/add/' + 0, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.closeModal()
      })
        .catch(error => {
          console.log('', error)
        })
    },
    showModal (id) {
      this.check_id = id
      this.isPopupShown = true
    },
    closeModal () {
      this.isPopupShown = false
      this.check_id = 0
    },
    handleBooks ($s) {
      this.$https('/author/' + this.id + `?page=${this.page}`, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          this.author = data.response[0]
          this.authorBooks = data.response[0].items
          this.title = this.author.label + ' ' + this.author.title
          this.isLoaded = true
          if (this.authorBooks.length) {
            this.page += 1
            this.authorBooks.forEach(e => {
              this.list.push(e)
            })
            $s.loaded()
          } else {
            $s.complete()
          }
        })
        .catch(error => {
          console.log('', error)
        })
    }
  }

}
</script>
<style lang="scss" scoped>
  .wrapper-authors {
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .app-content-box {
    min-height: 100vh;
  }
  .wrapper-cover {
  display: flex;
  justify-content: center;

  img {
    max-height: 270px;
    max-width: 210px;
  }
}
.stars-wrapper {
  display: flex;
  justify-content: flex-start;
}
.rating-size {
    font-size: 14px;
}
</style>
